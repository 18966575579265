<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t("agent.commision_head") }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("agent.commision_list") }} </v-card-title>
        <v-card-actions>
          <v-row>
            <v-col cols="12" xs="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <span class="white--text">
                    {{ $t("partner") }} : {{ name }} <br />
                    Commission
                  </span>
                </v-card-title>
                <v-card-actions>
                  <v-btn block @click="$router.push('/agent')">
                    <v-icon small class="pr-3">mdi-skip-backward</v-icon>
                    {{ $t("agent.commision_back") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <span class="white--text">{{ $t("agent.commision_summary_deposit_withdraw") }} </span>
                </v-card-title>
                <v-card-text />
                <v-card-actions>
                  <v-btn block @click="linkTo('agent-report-member')">
                    <v-icon small class="pr-3">mdi-card-text</v-icon>
                    {{ $t("agent.commision_deposit_withdraw_list") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" xs="12" md="4">
              <v-card color="warning">
                <v-card-title>
                  <span class="white--text"> {{ $t("agent.commision_all_user") }} : {{ refCount.toLocaleString() }} {{ $t("people") }}
                  </span>
                </v-card-title>
                <v-card-text />
                <v-card-actions>
                  <v-btn block @click="linkTo('agent-report-member-register')">
                    <v-icon small class="pr-3">mdi-account-group</v-icon>
                    {{ $t("agent.commision_user_report") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pb-0 d-flex align-start row mt-5">
          <span style="padding-left: 15px;">
            <date-picker-input ref="startDate" :date="dateFrom" :label="$t('agent.commision_date_from')"
              @setValue="dateFrom = $event" />
          </span>
          <span style="padding-left: 15px;">
            <date-picker-input ref="endDate" :date="dateTo" :label="$t('agent.commision_date_to')"
              @setValue="dateTo = $event" />
          </span>
          <v-btn color="primary" @click="searchRef" class="mx-5"> {{ $t("search") }} </v-btn>
        </v-card-actions>
        <!-- <v-card-actions class="pb-0 d-flex align-start row mt-5">
          <span>
            <date-picker-input ref="startDate" :date="dateFrom" label="วันที่(จาก)" @setValue="dateFrom = $event" />
          </span>
          <span>
            <date-picker-input ref="endDate" :date="dateTo" label="วันที่(ถึง)" @setValue="dateTo = $event" />
          </span>
          <v-btn color="primary" @click="searchRef" class="mx-5"> ค้นหา </v-btn>
        </v-card-actions> -->
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5">{{ $t("search") }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table style="width: 100%" mobile-breakpoint="0" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :page.sync="pagination.page" :loading="loading" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50, 100, 200, 300],
            }" :items="members">
            <template v-slot:body.append>
              <tr>
                <td style="text-align: center">{{ $t("agent.commision_summary") }}</td>
                <td style="text-align: center">
                  {{ total.deposit | currency }}
                </td>
                <td style="text-align: center">
                  {{ total.withdraw | currency }}
                </td>
                <td style="text-align: center">
                  {{ total.bonus | currency }}
                </td>
                <td style="text-align: center">{{ total.profit | currency }}</td>
              </tr>
              <tr>
                <td style="text-align: center; white-space: nowrap">{{ $t("agent.commision_summary_result") }} ({{ percent
                }} %)</td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center">{{ profitByPercent | currency }} </td>
              </tr>
            </template>
            <template v-slot:[`item.deposit`]="{ item }">
              <span>{{ item.deposit | currency }}</span>
            </template>
            <template v-slot:[`item.withdraw`]="{ item }">
              <span>{{ item.withdraw | currency }}</span>
            </template>
            <template v-slot:[`item.bonus`]="{ item }">
              <span>{{ item.bonus | currency }}</span>
            </template>
            <template v-slot:[`item.profit`]="{ item }">
              <span>{{ item.profit | currency }}</span>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },
  data() {
    return {
      name: '-',
      commission: '10.00%',
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      refCount: 0,
      breadcrumbsItems: [
        {
          text: this.$t('partner'),
          disabled: false,
          href: '/agent',
        },
      ],
      headers: [
        {
          text: this.$t('agent.commision_head1'),
          value: 'day',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head2'),
          value: 'deposit',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head3'),
          value: 'withdraw',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head4'),
          value: 'bonus',
          align: 'center',
          width: '100px',
          sortable: false,
        },
        {
          text: this.$t('agent.commision_head5'),
          value: 'profit',
          align: 'center',
          width: '100px',
          sortable: false,
        },
      ],
      valType: this.$t('agent.commision_role1'),
      roles: [this.$t('agent.commision_role1'), this.$t('agent.commision_role2'), this.$t('agent.commision_role3')],
      members: [],
      id: this.$route.params.id,
      loading: false,
      searchData: null,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 50,
        totalItems: 0,
      },
      total: {},
      percent: 0,
      profitByPercent: 0,
    }
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // if (cont) await this.getPartnerRef(newValue.page, newValue.itemsPerPage)
        // await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getPartnerRef(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  async created() {
    this.addLogPage()
    await this.$store.dispatch('getPartner', { id: this.id }).then(res => {
      this.name = res.data[0].name
      this.refCount = res.totalRef
    })
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'พันธมิตร',
            url: window.location.href,
            detail: 'รายการ Commission',
            admin: userSystem.name ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    linkTo(name) {
      this.$router.push({ name: name, params: { id: this.id } })
    },
    async searchRef() {
      this.dummy = false
      await this.getPartnerRef(1, this.pagination.rowsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      this.pagination.page = 1
    },
    async getPartnerRef(page, row, time_from, time_to, search) {
      this.loading = true
      this.members = []
      try {
        var rs = await this.$store.dispatch('getPartnerCommission', {
          id: this.id,
          page: page,
          rows: row,
          ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
          ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
          ...(search ? { search: search } : {}),
        })
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }

      // this.pagination.totalItems = rs.length
      this.pagination.totalItems = rs.result.length
      this.total = rs['total']
      this.profitByPercent = rs['profit_by_percent']
      this.percent = rs['percent']
      rs['result'].forEach(element => {
        this.members.push(element)
        // this.members.push({
        //   id: element.id,
        //   member: element.member.phone,
        //   deposit: element.deposit,
        //   withdraw: element.withdraw,
        //   profit: element.profit,
        //   // date: moment(element.created_at).format('YY-MM-DD HH:mm'),
        //   // wallet_auto: '0.00',
        //   // wallet_commission: '0.00',
        //   // username: element.member_game.username,
        //   // password: '',
        //   // date: moment(element.created_at).format('YY-MM-DD HH:mm'),
        // })
      })
      this.loading = false
    },
    cal(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getPartnerRef(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
