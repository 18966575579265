<template>
  <v-row>
    <v-col cols="12" class="d-flex">
      <v-btn-toggle class="d-flex align-start" 
      v-model="toggle_exclusive">
        <v-btn 
        disabled 
        id="custom-disabled"> 
        {{ label }}
        </v-btn>
      </v-btn-toggle>
        <v-select
        class="centered-input"
        :items="items"
        outlined
        dense>
        </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    items: {
        type: Array,
        default: () => []
    }
  },
  data() {
    return {
      toggle_exclusive: 0,
    }
  }
}
</script>

<style scoped>
#custom-disabled.v-btn--disabled {
  color: #424242 !important;
   opacity: 0.8;
    background-color: #FFFF !important;
}
 .centered-input >>> input {
      text-align: center
    }
</style>